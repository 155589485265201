<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-md-auto">
        <h1>{{ $t("platform.create_new") }}</h1>
      </div>
    </div>
    <div class="sep _h10"></div>
    <div class="row">
      <div
        v-for="(el, ind) in categories"
        :key="ind"
        class="col-12 col-md-6 col-lg-4 d-md-flex mb-4"
      >
        <div class="section">
          <h2>{{ $t(`platform.${el}.title`) }}</h2>
          <span class="info" :class="ind === 0 ? 'success' : 'error'">{{
            $t(`platform.need${ind === 0 ? "_no" : ""}_own_server`)
          }}</span>
          <div class="entry-content pt-2">
            <ul>
              <li class="pb-2">{{ $t(`platform.${el}.sub_1`) }}</li>
              <li class="pb-2">{{ $t(`platform.${el}.sub_2`) }}</li>
              <li class="pb-2">
                {{ $t(`platform.${el}.sub_3`) }}
              </li>
            </ul>
          </div>
          <router-link
            :to="`/create-platform/${el}${el !== 'arbitrage' ? '/1' : ''}`"
            class="btn mb-2 _mob100"
            :class="{ '_bg-green mb-2': el !== 'arbitrage' }"
          >
            {{ $t(`platform.${el}.create`) }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ChoosePlatform",
  data() {
    return {
      categories: ["arbitrage", "site", "landing"],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
};
</script>
<style lang="scss" scoped>
.section {
  background: #f7f7f9;
  border-radius: 15px;
  padding: 25px 42px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: 1200px) {
    padding: 25px 30px;
  }

  .info {
    font-size: 12px;

    &.success {
      color: green;
    }

    &.error {
      color: red;
    }
  }
}
</style>