<template>
  <div>
    <ChoosePlatform />
  </div>
</template>

<script>
import "@/assets/css/_page-platform.scss";
import "@/assets/css/_section.scss";
import ChoosePlatform from "@/components/ChoosePlatform.vue";

export default {
  name: "CreatePlatform",
  components: {
    ChoosePlatform,
  },
};
</script>



